export class HomeModel {
  constructor() {
    this.date = 0;
    this.client_cache_time = 0;
    this.user = new UserModel();
    this.banners = new Banners();
    this.notices = [];
    this.ranks = new RankModel();
    this.contents = new ContentsModel();
    this.announcement = new AnnouncementModel();
    this.events = [];
  }

  setData(initializer) {
    if (initializer) {
      this.date = initializer.date || 0;
      this.client_cache_time = initializer.client_cache_time || 0;

      let UserModel = this.user;
      UserModel.setData(initializer.user);

      let BannerModel = this.banners;
      BannerModel.setData(initializer.banners);

      let RankModel = this.ranks;
      RankModel.setData(initializer.ranks);

      let ContentsModel = this.contents;
      ContentsModel.setData(initializer.contents);

      this.user = UserModel;
      this.banners = BannerModel;
      this.notices = initializer.notices || [];
      this.ranks = RankModel;
      this.contents = ContentsModel;
      this.events = initializer.events || [];

      let AnnouncementModel = this.announcement;
      AnnouncementModel.setData(initializer.announcement);
    }
  }
}

export class UserModel {
  constructor() {
    this.idol_info = new IdolInfoModel();
  }

  setData(initializer) {
    if (initializer) {
      let IdolInfoModel = this.idol_info;
      IdolInfoModel.setData(initializer.idol_info, true);

      this.idol_info = IdolInfoModel;
    }
  }
}

class IdolInfoModel {
  constructor() {
    this.bias_info = {};
    this.idols = [];
    this.idol_ids = []; // 내 아이돌 아이디 array로 저장
  }

  setData(initializer, isMy) {
    this.idols = [];

    if (initializer) {
      if (initializer.idols) {
        for (let idol of initializer.idols) {
          let model = new IdolModel();
          model.setData(idol);

          this.idols.push(model);

          // 최애 아이돌 정보
          if (idol.bias_yn == 'Y') {
            this.bias_info = idol;
          }

          if (isMy) {
            // user > idol_info 일 경우에만 아이돌 아이디 저장
            this.idol_ids.push(model.idol_id);
          }
        }
      }
    }
  }
}

export class Banners {
  constructor() {
    this.top_banners = [];
    this.placards = [];
  }

  setData(initializer) {
    if (initializer) {
      this.top_banners = initializer.top_banners;
      this.placards = initializer.placards;
    }
  }
}

export class RankModel {
  constructor() {
    this.top_ranking = [];
    this.rankings = [];
  }

  setData(initializer) {
    this.top_ranking = [];
    this.rankings = [];

    if (initializer) {
      if (initializer.top_ranking) {
        for (let topRanking of initializer.top_ranking) {
          let model = new RankDetailModel();
          model.setData(topRanking);

          this.top_ranking.push(model);
        }
      }

      if (initializer.rankings) {
        for (let ranking of initializer.rankings) {
          let model = new RankDetailModel();
          model.setData(ranking);

          this.rankings.push(model);
        }
      }
    }
  }
}

export class RankDetailModel {
  constructor() {
    this.type = '';
    this.idol = new IdolModel();
    this.rank = 0;
    this.reward = 0;
    this.thumb_img_url = '';
    this.gap_info = {
      rank: 0,
      reward: 0
    };
  }

  setData(initializer) {
    if (initializer) {
      this.type = initializer.type;

      let model = this.idol;
      model.setData(initializer.idol);
      this.idol = model;

      this.rank = initializer.rank;
      this.reward = initializer.reward;
      this.thumb_img_url = initializer.thumb_img_url;

      if (initializer.gap_info != null) {
        this.gap_info = {
          rank: initializer.gap_info.rank,
          reward: initializer.gap_info.reward
        };
      }
    }
  }
}

class IdolModel {
  constructor() {
    this.idol_id = 0;
    this.idol_name = '';
    this.idol_img_url = '';
    this.bias_yn = '';
  }

  setData(initializer) {
    if (initializer) {
      this.idol_id = initializer.idol_id;
      this.idol_name = initializer.idol_name;
      this.idol_img_url = initializer.idol_img_url;
      this.bias_yn = initializer.bias_yn;
    }
  }
}

class ContentsModel {
  constructor() {
    this.vote = new VoteModel();
    this.ad_fund = new AdFundModel();
    this.ad_fandom = new AdFandomModel();
    this.fund = new FundModel();
    this.clip = new ClipModel();
  }

  setData(initializer) {
    if (initializer) {
      let VoteModel = this.vote;
      VoteModel.setData(initializer.vote);

      let AdFundModel = this.ad_fund;
      AdFundModel.setData(initializer.ad_fund);

      let AdFandomModel = this.ad_fandom;
      AdFandomModel.setData(initializer.ad_fandom);

      let FundModel = this.fund;
      FundModel.setData(initializer.fund);

      let ClipModel = this.clip;
      ClipModel.setData(initializer.clip);

      this.clip = ClipModel;
    }
  }
}

export class ContentsListModel {
  constructor() {
    this.current_page = 1;
    this.total_page = 0;
    this.total_count = 0;
    this.list = [];
  }

  setData(initializer) {
    if (initializer) {
      this.setPageInfo(initializer);
      this.total_count = initializer.total_count ? initializer.total_count : 0;
      this.list = initializer.list;
    }
  }

  setPageInfo(initializer) {
    this.current_page = initializer.current_page ? initializer.current_page : 1;
    this.total_page = initializer.total_page ? initializer.total_page : 0;
  }
}

export class VoteModel {
  constructor() {
    this.notifys = [];
    this.votes = { hot: [], closest: [] };
  }

  setData(initializer) {
    if (initializer) {
      this.setNotifyList(initializer);
      this.setListData(initializer);
    }
  }

  setNotifyList(initializer) {
    if (!initializer || !Object.prototype.hasOwnProperty.call(initializer, 'notifys')) {
      return;
    }
    let notifys = initializer.notifys;

    this.notifys = notifys.map(value => {
      let voteListModel = new VoteListModel();
      voteListModel.setData(value);
      return voteListModel;
    });
  }

  setListData(initializer) {
    if (!initializer || !Object.prototype.hasOwnProperty.call(initializer, 'votes')) {
      return;
    }
    let votes = initializer.votes;
    votes.map(vote => {
      this.votes[vote.type] = vote.list.map(value => {
        let voteListModel = new VoteListModel();
        voteListModel.setData(value);
        return voteListModel;
      });
    });
  }
}

export class VoteListModel {
  constructor() {
    this.id = '';
    this.title = '';
    this.active_start_dt = 0;
    this.active_end_dt = 0;
    this.category = '';
    this.sub_title = '';
    this.thumb_img_url = '';
    this.sub_img = '';
    this.my_idol_yn = 'N';
  }

  setData(initializer) {
    if (initializer) {
      this.id = initializer.id;
      this.title = initializer.title;
      this.active_start_dt = initializer.active_start_dt ? initializer.active_start_dt : 0;
      this.active_end_dt = initializer.active_end_dt ? initializer.active_end_dt : 0;
      this.category = initializer.category;
      this.sub_title = initializer.sub_title;
      this.thumb_img_url = initializer.thumb_img_url;
      this.sub_img = initializer.sub_img;
      this.my_idol_yn = initializer.my_idol_yn ? initializer.my_idol_yn : 'N';
    }
  }
}

export class AdFundModel extends ContentsListModel {
  constructor() {
    super();
  }

  setData(initializer) {
    if (initializer) {
      super.setData(initializer);
    }
  }
}

export class AdFandomModel extends ContentsListModel {
  constructor() {
    super();
  }

  setData(initializer) {
    if (initializer) {
      super.setData(initializer);
    }
  }
}

export class FundModel extends ContentsListModel {
  constructor() {
    super();
  }

  setData(initializer) {
    if (initializer) {
      super.setData(initializer);
    }
  }
}

export class ClipModel {
  constructor() {
    this.new_clip = null;
    this.hot_clip = null;
  }

  setData(initializer) {
    if (initializer) {
      this.new_clip = initializer.new_clip;
      this.hot_clip = initializer.hot_clip;
    }
  }
}

export class AnnouncementModel {
  constructor() {
    this.id = '';
    this.title = '';
    this.created_at = null;
    this.show_start_at = null;
    this.is_important = false;
  }

  setData(initializer) {
    if (initializer) {
      this.id = initializer.id;
      this.title = initializer.title;
      this.created_at = initializer.created_at;
      this.show_start_at = initializer.show_start_at;
      this.is_important = initializer.is_important;
    }
  }
}
