import _ from 'lodash';
import semver from 'semver';

const NATIVE_FUNCTION_SHOW_PROGRESS = 'showProgress';
const NATIVE_FUNCTION_HANDLE_ERROR = 'handleError';
export const NATIVE_FUNCTION_GET_SESSION = 'getSession';

const sessionStorage = {
  get(key) {
    return window.sessionStorage.getItem(key);
  },
  set(key, value) {
    window.sessionStorage.setItem(key, value);
  },
  delete(key) {
    window.sessionStorage.removeItem(key);
  },
  clear() {
    window.sessionStorage.clear();
  }
};

const localStorage = {
  get(key) {
    return window.localStorage.getItem(key);
  },
  set(key, value) {
    window.localStorage.setItem(key, value);
  },
  clear() {},
  save(keys) {
    for (let key of keys) {
      if (!this.getItem(key)) {
        this.setItem(key, keys[key]);
      }
    }
  }
};

/**
 *
 * @param {boolean} status
 */
const sendStatusOfIsLoading = status => {
  callNativeFunction(NATIVE_FUNCTION_SHOW_PROGRESS, { value: status });
};

/**
 * native 함수 호출
 *
 * @param {String} action - 네이티브 함수 이름
 * @param {Object} object - 전달 파라미터 (object)
 */
const callNativeFunction = (action, object) => {
  // 모바일 체크
  if (navigator.platform) {
    if ('win16|win32|win64|mac|macintel'.indexOf(navigator.platform.toLowerCase()) > -1) {
      return;
    }
  }

  // action에 함수명 추가 (ios 에서만 사용하지만 동일한 데이터 전달)
  object.action = action;

  let jsonStr = JSON.stringify(object);

  /**
   *  jsonStr
   *
   *      showProgress : {"action":"showProgress", "value":true}
   *      handleError  : {"action":"handleError", "code":"EAPI_INVALID_SESSION", "message":"Invalid session."}
   *      getSession   : {"action":"getSession", "value":true}
   */

  if (navigator.userAgent.match(/iP(hone|od|ad)/)) {
    // console.log(`[iOS]\nfunc = ${action} \nparam = ${jsonStr}`);
    window.webkit.messageHandlers.ichamp.postMessage(jsonStr);
  } else {
    // console.log(`[Android]\nfunc = ${action}\nparam = ${jsonStr}`);

    // android : action 에 따라 함수 호출 분기
    switch (action) {
      case NATIVE_FUNCTION_SHOW_PROGRESS:
        ichamp.showProgress(jsonStr);
        break;
      case NATIVE_FUNCTION_HANDLE_ERROR:
        ichamp.handleError(jsonStr);
        break;
      case NATIVE_FUNCTION_GET_SESSION:
        return ichamp.getSession();
    }
  }
};

const genDeepLink = (item_type, item_value, item_data) => {
  let urlSchema = 'https://app.idolchamp.com';
  let path = ['open'];
  let deeplink = new URL(urlSchema);

  // 배너 외 기능 정의 (배너는 딥링크)
  switch (item_type) {
    case 'ANNOUNCEMENT':
      path.push('announcement');
      break;
    case 'RANK':
      path.push('navi');
      path.push('chart');
      break;
    case 'COMMUNITY':
      path.push('navi');
      path.push('community');
      break;
    case 'RANK_JOIN':
      path.push('chamsim-add');
      break;
    case 'EVENT':
      path.push('event');
      break;
    case 'FUND':
      path.push('starnanum');
      break;
    case 'CLIP':
      path.push('clip');
      break;
    case 'VOTE':
      path.push('vote');
      break;
    case 'AD_FANDOM':
      path.push('fund');
      path.push('detail');
      break;
    case 'AD_FANDOM_CREATE':
      path.push('fund');
      path.push('create');
      break;
    case 'AD_FANDOM_TAB':
      path.push('navi');
      path.push('fund');
      break;
    case 'AD_FANDOM_SEARCH':
      path.push('fund');
      path.push('search');
      break;
  }

  if (item_value) {
    path.push(item_value);
  }
  deeplink.pathname = path.join('/');

  if (item_data) {
    for (const [key, value] of Object.entries(item_data)) {
      deeplink.searchParams.set(key, value);
    }
  }
  return deeplink;
};

const moveDeeplink = obj => {
  const { item_type, item_value, item_data } = obj;

  // 딥링크로 전환 (클라이언트에서 URL 인터셉트)
  window.location.href = genDeepLink(item_type, item_value, item_data);
};

const encodeBase64 = str => {
  // base64 encode (한글 때문에 encodeURIComponent로 모든 문자 인코딩)
  return window.btoa(encodeURIComponent(str));
};

const decodeBase64 = str => {
  // base64 decode
  return decodeURIComponent(window.atob(str));
};

/**
 * 홈메인 데이터 여부 및 캐싱 시간으로 api 호출 여부 체크
 *
 * @return true : api 호출 o (캐싱 된 홈 데이터 없거나 캐싱 시간 지난 경우)
 *         false : api 호출 x
 */
const checkCallApiWithHomeCache = () => {
  let now = Date.now() / 1000;
  let homeData = sessionStorage.get('h') ? JSON.parse(decodeBase64(sessionStorage.get('h'))) : null;
  let writeTime = sessionStorage.get('hDate') || 0;

  // 캐싱 된 홈 데이터 없거나 캐싱 시간 지난 경우 -> api 호출
  let result = !homeData || homeData.client_cache_time < now - writeTime;
  if (result) {
    // api 호출 시 client cache time 다시 체크하기 위해 저장하고 있는 시간 현재 시간으로 초기화
    // console.log('client cache :: set hDate now');
    sessionStorage.set('hDate', now);
  }

  return result;
};

const getSessionHeader = () => {
  let sessionHeaders = {};
  if (sessionStorage.get('headers')) {
    sessionHeaders = JSON.parse(atob(sessionStorage.get('headers')));
  } else {
    let appSessionData = callNativeFunction(NATIVE_FUNCTION_GET_SESSION, { value: true });
    if (appSessionData) {
      sessionHeaders = JSON.parse(atob(appSessionData));
      sessionStorage.set('headers', appSessionData);
    }
  }

  if (sessionHeaders) {
    // use headers
    const useHeaders = [
      'session_id',
      'fingerprint',
      'devicetype',
      'ccd',
      'lang',
      'app_version',
      'width',
      'height',
      'density',
      'os_version',
      'model'
    ];
    sessionHeaders = _.pick(sessionHeaders, useHeaders);
  }
  return sessionHeaders;
};

// 스토어 배포 버전 분기
const isStoreAppVersion = (underAos, underIos) => {
  const headers = getSessionHeader();
  let result = true;

  // 확인할 스토어 버전 정보가 없는 경우 false
  underAos = semver.valid(underAos) ? underAos : semver.valid(semver.coerce('v99')); // 99.0.0
  underIos = semver.valid(underIos) ? underIos : semver.valid(semver.coerce('v99')); // 99.0.0

  if (semver.valid(headers.app_version) !== null) {
    const version = semver.valid(semver.coerce(headers.app_version));
    switch (headers.devicetype.toUpperCase()) {
      case 'ANDROID':
        result = semver.lte(version, underAos);
        break;
      case 'IOS':
        result = semver.lte(version, underIos);
        break;
      default:
        break;
    }
  }

  return result;
};

const isGreaterAppVersion = (upperAos, upperIos) => {
  if (!sessionStorage.get('headers')) {
    return false;
  }
  let headers = getSessionHeader();
  let result = true;

  // 확인할 스토어 버전 정보가 없는 경우 false
  upperAos = semver.valid(upperAos) ? upperAos : semver.valid(semver.coerce('v0')); // 99.0.0
  upperIos = semver.valid(upperIos) ? upperIos : semver.valid(semver.coerce('v0')); // 99.0.0

  if (semver.valid(headers.app_version) !== null) {
    const version = semver.valid(semver.coerce(headers.app_version));
    switch (headers.devicetype.toUpperCase()) {
      case 'ANDROID':
        result = semver.gte(version, upperAos);
        break;
      case 'IOS':
        result = semver.gte(version, upperIos);
        break;
      default:
        break;
    }
  }

  return result;
};

export default {
  NATIVE_FUNCTION_HANDLE_ERROR,
  sessionStorage,
  localStorage,
  sendStatusOfIsLoading,
  callNativeFunction,
  encodeBase64,
  decodeBase64,
  checkCallApiWithHomeCache,
  getSessionHeader,
  isStoreAppVersion,
  isGreaterAppVersion,
  moveDeeplink
};
