import api from '@/api';
import { HomeModel, RankModel, UserModel } from '@/model/home/HomeModel.js';
import { FandomModel } from '@/model/fandom/FandomModel.js';
import common from '@/utils/common.js';
import _ from 'lodash';

const home = {
  // state : 프로젝트에서 공통으로 사용할 변수 정의
  state: {
    homeData: new HomeModel(),
    userData: new UserModel(),
    writeHomeDate: 0,
    noticeIdx: 0,
    fandomData: new FandomModel()
  },
  // actions : Mutations를 실행시키는 역활
  //             비동기 처리 되며 dispatch('함수명', '전달인자')으로 실행
  actions: {
    INIT_HOME_DATA({ commit }) {
      common.sendStatusOfIsLoading(true);
      if (common.checkCallApiWithHomeCache()) {
        if (common.isGreaterAppVersion('1.3.2905', '1.0.2')) {
          api
            .getHomeFirstDataRequest(5)
            .then(response => {
              let isCache = response.client_cache_time > 0 ? true : false;
              commit('SET_HOME_DATA', {
                response: response,
                isCache: isCache
              });
              common.sendStatusOfIsLoading(false);
            })
            .catch(error => {
              commit('API_ERROR_HANDLER', { response: error });
            });

          api
            .getHomeAfterDataRequest(5)
            .then(response => {
              let isCache = response.client_cache_time > 0 ? true : false;
              commit('SET_HOME_DATA', {
                response: response,
                isCache: isCache
              });
              common.sendStatusOfIsLoading(false);
            })
            .catch(error => {
              commit('API_ERROR_HANDLER', { response: error });
            });
        } else {
          api
            .getHomeDataRequest(5)
            .then(response => {
              let isCache = response.client_cache_time > 0 ? true : false;
              commit('SET_HOME_DATA', {
                response: response,
                isCache: isCache
              });
              common.sendStatusOfIsLoading(false);
            })
            .catch(error => {
              commit('API_ERROR_HANDLER', { response: error });
            });
        }
      } else {
        let homeData = null;
        if (common.sessionStorage.get('h')) {
          homeData = JSON.parse(common.decodeBase64(common.sessionStorage.get('h')));
        }

        commit('SET_HOME_DATA', {
          response: homeData,
          isCache: false
        });
        common.sendStatusOfIsLoading(false);
      }
    },
    SET_HOME_DATA({ commit }, data) {
      commit('SET_HOME_DATA', data);
    },
    REFRESH_HOME_RANK({ commit }) {
      if (!common.checkCallApiWithHomeCache()) {
        return;
      }

      common.sendStatusOfIsLoading(true);

      api
        .getHomeRankRequest()
        .then(response => {
          commit('SET_HOME_RANK_DATA', response);
          common.sendStatusOfIsLoading(false);
        })
        .catch(error => {
          commit('API_ERROR_HANDLER', { response: error });
        });
    },

    /**
     *
     * @param commit
     * @param dispatch
     * @param {number} idx - index of notice
     */
    SET_NOTICE_INDEX({ commit }, idx) {
      commit('SET_NOTICE_INDEX', idx);
    },

    /**
     *
     * @param commit
     * @param dispatch
     * @param {string} header - index of notice
     */
    SET_HEADER({ commit }, header) {
      commit('SET_HEADER', header);
    }
  },

  // mutations : state를 변경시키는 역활 (Mutations을 통해서만 state를 변경해야 함)
  //             동기 처리 되며 commit('함수명', '전달인자')으로 실행
  mutations: {
    SET_HOME_DATA(state, data) {
      let model = state.homeData;
      model.setData(_.merge(state, data.response));
      state.homeData = model;

      let user = state.userData;
      user.setData(data.response.user);
      state.userData = user;

      if (data.isCache) {
        state.writeHomeDate = Date.now() / 1000;

        let encodeStr = common.encodeBase64(JSON.stringify(data.response));

        common.sessionStorage.set('hDate', state.writeHomeDate);
        common.sessionStorage.set('h', encodeStr);
      }
    },
    SET_HOME_RANK_DATA(state, data) {
      let model = new RankModel();
      model.setData(data);
      state.homeData.ranks = model;
    },
    API_ERROR_HANDLER(state, { response }) {
      console.error(response);

      common.sendStatusOfIsLoading(false);

      if (!response) {
        common.callNativeFunction(common.NATIVE_FUNCTION_HANDLE_ERROR, response);
      } else {
        let code = response.code ? response.code : response.name;
        if (code === 'EAPI_INVALID_SESSION') {
          common.localStorage.clear();
        } else {
          common.sessionStorage.delete('h');
        }

        if (response.error) {
          common.callNativeFunction(common.NATIVE_FUNCTION_HANDLE_ERROR, response.error);
        } else {
          common.callNativeFunction(common.NATIVE_FUNCTION_HANDLE_ERROR, {
            code: code ? code : 'ERROR',
            message: response.message
          });
        }
      }
    },
    SET_NOTICE_INDEX(state, idx) {
      common.localStorage.set('n', idx);
      state.noticeIdx = idx;
    },
    SET_HEADER(state, queryHeader) {
      try {
        if (queryHeader) {
          JSON.parse(atob(queryHeader));
          common.sessionStorage.set('headers', queryHeader);
        }
      } catch (e) {
        console.error(e);
      }
    }
  },

  getters: {
    getHomeData({ homeData }) {
      return homeData;
    },
    getUserData({ userData }) {
      return userData;
    },
    getNoticeIdx(state) {
      return state.noticeIdx;
    },
    getFandomData({ fandomData }) {
      return fandomData;
    }
  }
};

export default home;
