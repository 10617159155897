// 아이템 모델
export class FandomItemModel {
  constructor() {
    this.client_cache_time = 0;
    this.date = 0; // Joi.number().required().description('현재 날짜 - timestamp (UTC)'),
    this.user = {}; // UserScheme.User({}).required().description("유저 정보"),
    this.id = 0; // Joi.number().optional().description('광고 모금 ID'),
    this.type = ''; // Joi.string().description('광고 영역'),
    this.nickname = ''; // Joi.string().description('닉네임').allow(null).allow(''),
    this.title = ''; // Joi.string().description('광고주제').allow(null).allow(''),
    this.desc = ''; // Joi.string().description('내용').allow(null).allow(''),
    this.sum_reward = 0; // Joi.number().optional().description('현재 모금챔심'),
    this.goal_reward = 0; // Joi.number().optional().description('목표 모금챔심'),
    this.end_dt = ''; // Joi.string().optional().description('모금 마감일'),
    this.active_end_dt = 0; // Joi.number().optional().description('모금 마감일'),
    this.join_end_yn = ''; // Joi.string().optional().description('모금 참여가능 여부'),
    this.goal_yn = ''; // Joi.string().optional().description('목표 달성 여부'),
    this.kind_code = ''; // Joi.string().optional().description('모금 상태'),
    this.join_count = 0; // Joi.number().optional().description('참여자 수'),
    this.pick_yn = ''; // Joi.string().optional().description('아챔픽모금 여부'),
    this.event_yn = ''; // Joi.string().optional().description('이벤트모금 여부'),
    this.goal_max_yn = ''; // Joi.string().optional().description('미션 모금 최대값 제한 여부 '),
    this.goal_max_name = ''; // Joi.string().optional().description('미션 모금 최대값 제한 여부 문구 ').allow(null).allow(''),
    this.mission = {}; // Joi.object().optional().description('미션 목록'),
    this.join_type = ''; // Joi.string().allow(['time', 'heart', 'time_heart']).required().description('사용 챔심 종류 - time_heart(타임/하트(루비)챔심), time(타임챔심만), heart(하트(루비)챔심만), star(스타챔심만)'),
    this.idol_id = 0; // Joi.number().description('아이돌 아이디').allow(null),
    this.idol_name = ''; // Joi.string().required().description('아이돌 이름 (다국어 대응)'),
    this.bg_img_url = ''; // Joi.string().optional().description('모금 상단 이미지').allow(null).allow(''),
  }

  setData(initializer) {
    if (initializer) {
      this.client_cache_time = initializer.client_cache_time || 0;
      this.date = initializer.date || 0;
      this.user = initializer.user || {};
      this.id = initializer.id || 0;
      this.type = initializer.type || '';
      this.nickname = initializer.nickname || '';
      this.title = initializer.title || '';
      this.desc = initializer.desc || '';
      this.sum_reward = initializer.sum_reward || 0;
      this.goal_reward = initializer.goal_reward || 0;
      this.end_dt = initializer.end_dt || '';
      this.active_end_dt = initializer.active_end_dt || 0;
      this.join_end_yn = initializer.join_end_yn || '';
      this.goal_yn = initializer.goal_yn || '';
      this.kind_code = initializer.kind_code || '';
      this.join_count = initializer.join_count || 0;
      this.pick_yn = initializer.pick_yn || '';
      this.event_yn = initializer.event_yn || '';
      this.goal_max_yn = initializer.goal_max_yn || '';
      this.goal_max_name = initializer.goal_max_name || '';
      this.mission = initializer.mission || {};
      this.join_type = initializer.join_type || '';
      this.idol_id = initializer.idol_id || 0;
      this.idol_name = initializer.idol_name || '';
      this.bg_img_url = initializer.bg_img_url || '';
    }
  }
}

// 목록 모델
export class FandomListModel {
  constructor() {
    this.current_page = 1;
    this.total_page = 0;
    this.total_count = 0;
    this.list = [];
  }

  setData(initializer) {
    if (initializer) {
      this.setPageInfo(initializer);
      this.total_count = initializer.total_count ? initializer.total_count : 0;

      if (initializer.list) {
        for (let item of initializer.list) {
          let itemModel = new FandomItemModel();
          itemModel.setData(item);

          this.list.push(itemModel);
        }
      }
      this.list = initializer.list;
    }
  }

  setPageInfo(initializer) {
    this.current_page = initializer.current_page ? initializer.current_page : 1;
    this.total_page = initializer.total_page ? initializer.total_page : 0;
  }
}

// 팬덤광고 모델
export class FandomModel {
  constructor() {
    this.default = new FandomListModel();
    this.search = new FandomListModel(); // 검색 모델 세팅 필요
  }

  setData(initializer) {
    if (initializer) {
      this.default.setData(initializer.list);
      this.search.setData(initializer.search);
    }
  }
}
