import _ from 'lodash';
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/routes/routes';
import common from '@/utils/common.js';
import { setLocale } from '@/locales';
import { store } from '@/store/index';

Vue.use(VueRouter);

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes: routes
});

router.beforeEach(async (to, from, next) => {
  // 헤더 세팅
  await store.dispatch('SET_HEADER', to.query.headers);

  // 헤더 확인
  const sessionHeaders = common.getSessionHeader();
  if (to.matched.some(record => record.meta.requiredAuth)) {
    // 권한이 필요한데 해더가 없으면 재시도 UI로 이동
    if (_.isEmpty(sessionHeaders)) {
      console.error('need login');
      // 재인증 화면 디자인 필요
      // next({
      //   path: '/auth',
      //   query: { redirect: to.fullPath }
      // });
      next();
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(to => {
  const sessionHeader = common.getSessionHeader();
  let lang = '';
  if (sessionHeader) {
    lang = sessionHeader.lang;
  }
  if (to.params.lang) {
    lang = to.params.lang;
  }
  setLocale(lang, to.params.localeSuffix);
});

export default router;
