import Vue from 'vue';
import router from '@/routes';
import { store } from '@/store';
import App from '@/App.vue';
import i18n from '@/locales';

import jquery from 'jquery';
import { Plugin } from 'vue-fragment';
// import ES6 style
import { VueMasonryPlugin } from 'vue-masonry';

window.$ = jquery;

Vue.use(Plugin);

Vue.use(VueMasonryPlugin);

const app = new Vue({
  router,
  i18n,
  store,
  // directives: {infiniteScroll},
  render: h => h(App)
}).$mount('#app');

export default app;
