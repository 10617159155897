import request from '@/utils/request';

function apiVersionStr(version, url) {
  return `/api/v${version}${url}`;
}

const getHomeDataRequest = (version = 4) => {
  let url = apiVersionStr(version, '/w/home');
  return request({
    url: url,
    method: 'get'
  });
};

const getHomeFirstDataRequest = (version = 5) => {
  let url = apiVersionStr(version, '/w/home/first');
  return request({
    url: url,
    method: 'get'
  });
};

const getHomeAfterDataRequest = (version = 5) => {
  let url = apiVersionStr(version, '/w/home/after');
  return request({
    url: url,
    method: 'get'
  });
};

const getHomeRankRequest = () => {
  let url = apiVersionStr(2, '/rank/home');
  return request({
    url: url,
    method: 'get'
  });
};

const getAdFandomData = params => {
  let url = `/ad/fandom/list?v=1`;
  return request({
    url: url,
    method: 'get',
    params: {
      menu: params.menu,
      category_code: params.category_code,
      size: 20,
      page: params.page,
      idol_ids: params.idol_ids,
      media_code: params.media_code,
      live_date: params.live_date
    }
  });
};

export default {
  getHomeDataRequest,
  getHomeRankRequest,
  getAdFandomData,
  getHomeFirstDataRequest,
  getHomeAfterDataRequest
};
