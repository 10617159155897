import _ from 'lodash';
import axios from 'axios';
import common, { NATIVE_FUNCTION_GET_SESSION } from '@/utils/common';

const errorHandlingFunc = error => {
  if (!error.response) {
    error.response = error.toJSON();
  }

  if (error.response.message) {
    if (error.response.message.indexOf('Network Error') > -1) {
      error.response.code = 'NETWORK_ERROR';
    }
  }

  return Promise.reject(error.response);
};

// create an axios instance
const service = axios.create({
  baseURL: `${HOST_API}`,
  // baseURL:'http://192.168.0.37:3080',
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // 세션 스토리지 헤더정보
    let sessionHeaders = common.getSessionHeader();

    let appSessionData = common.callNativeFunction(NATIVE_FUNCTION_GET_SESSION, { value: true });
    if (appSessionData) {
      sessionHeaders = JSON.parse(atob(appSessionData));
    }

    if (sessionHeaders) {
      // cors allow headers
      const allowHeaders = [
        'session_id',
        'fingerprint',
        'devicetype',
        'ccd',
        'lang',
        'app_version',
        'width',
        'height',
        'density',
        'os_version',
        'model'
      ];
      const headers = Object.assign(config.headers, sessionHeaders);
      config.headers = _.pick(headers, allowHeaders);
    }

    if (_.isEmpty(config.headers)) {
      return Promise.reject(config);
    }

    return Promise.resolve(config);
  },
  error => {
    return errorHandlingFunc(error);
  }
);

service.interceptors.response.use(
  response => {
    if (response.data) {
      if (response.data.error) {
        return Promise.reject(response.data.error);
      }
    }

    return Promise.resolve(response.data.value);
  },
  error => {
    return errorHandlingFunc(error);
  }
);

export default service;
