<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {},
  methods: {},
  computed: {}
};

// development 모드에서 자동 빌드 시 콘솔 로그 지워줌
window.addEventListener('message', e => {
  if (process.env.NODE_ENV !== 'production' && e.data && e.data.type === 'webpackInvalid') {
    console.clear();
  }
});
</script>
