import common from '@/utils/common';
import { store } from '@/store/index';

export default [
  {
    name: 'index',
    path: '/',
    beforeEnter: async (to, from, next) => {
      const sessionHeaders = common.getSessionHeader();
      next({
        path: '/' + sessionHeaders.lang + '/home',
        replace: true
      });
    }
  },
  {
    name: 'LangFandomList',
    path: '/:lang/fandom',
    component: () => import(/* webpackChunkName : "fandom-list" */ `@/views/fandom/List.vue`),
    title: 'FandomList',
    meta: { requiredAuth: true },
    beforeEnter: async (to, from, next) => {
      return next();
    }
  },
  {
    name: 'LangFandomSearchList',
    path: '/:lang/fandom/search',
    component: () => import(/* webpackChunkName : "fandom-search" */ '@/views/fandom/search/List.vue'),
    title: 'FandomSearchList',
    meta: { requiredAuth: true },
    beforeEnter: async (to, from, next) => {
      return next();
    }
  },
  {
    name: 'FandomList',
    path: '/fandom',
    component: () => import(/* webpackChunkName : "fandom-list" */ `@/views/fandom/List.vue`),
    title: 'FandomList',
    meta: { requiredAuth: true },
    beforeEnter: async (to, from, next) => {
      return next();
    }
  },
  {
    name: 'FandomSearchList',
    path: '/fandom/search',
    component: () => import(/* webpackChunkName : "fandom-search" */ '@/views/fandom/search/List.vue'),
    title: 'FandomSearchList',
    meta: { requiredAuth: true },
    beforeEnter: async (to, from, next) => {
      return next();
    }
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import(/* webpackChunkName : "fandom-list" */ `@/views/common/auth.vue`),
    beforeEnter: async (to, from, next) => {
      next();
    }
  },
  {
    name: 'NotFound',
    path: '*',
    component: () => import(/* webpackChunkName : "fandom-list" */ `@/views/common/404.vue`),
    beforeEnter: async (to, from, next) => {
      next();
    }
  },
  {
    name: 'LangHome',
    path: '/:lang/home',
    component: () => import(/* webpackChunkName : "home" */ `@/views/home/Home.vue`),
    title: 'Home',
    meta: { requiredAuth: true },
    beforeEnter: async (to, from, next) => {
      // 마지막 공지사항의 index 확인 후 없을 경우 0으로 있을 경우 n + 1로 공지사항 인덱스 처리
      let noticeIdx = parseInt(common.localStorage.get('n'));
      if (noticeIdx === undefined || noticeIdx === null || Number.isNaN(noticeIdx)) {
        await store.dispatch('SET_NOTICE_INDEX', 0);
      } else {
        await store.dispatch('SET_NOTICE_INDEX', noticeIdx + 1);
      }
      return next();
    }
  }
];
